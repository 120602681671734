import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class FaqV1 extends Component {

    render() {

        const faqData = [
            // {
            //     question: 'How can I find and book an Airbnb rental property through your company?',
            //     answer: 'To find and book a rental property through our company, simply browse our website and select the property that best suits your needs. Once you\'ve found a property you\'re interested in, you can book it directly through our website or through our partners.'
            // },
            {
                question: 'Can I see photos of the property before I book it?',
                answer: 'Yes, you can view photos of the rental property before booking it. We provide detailed photographs of the property, including both the interior and exterior, to give you a clear idea of what to expect.'
            },
            {
                question: 'What amenities are included in the rental property?',
                answer: 'The amenities included in each rental property may vary, but we typically provide a comprehensive list of amenities on our website for each property. This may include things like SmartTv,Wi-Fi, kitchen appliances, linens, towels, and toiletries.'
            },
            {
                question: 'How do I check in and get access to the rental property?',
                answer: 'Upon booking your rental property, we will provide you with detailed instructions on how to check in and gain access to the property. This may include a code for a lockbox or keyless entry, or instructions on where to pick up a key.'
            },
            {
                question: 'What if there are issues with the rental property during my stay?',
                answer: 'If you encounter any issues with the rental property during your stay, we encourage you to contact us immediately so that we can resolve the issue as quickly as possible.'
            },
            {
                question: 'How do I extend my stay or modify my booking?',
                answer: 'To extend your stay or modify your booking, please contact our customer support team, and we will do our best to accommodate your request.'
            },
            // {
            //     question: 'What is your cancellation policy for Airbnb rental bookings?',
            //     answer: 'Our cancellation policy for our  rental bookings may vary depending on the property and the length of your stay. Please refer to the specific cancellation policy for the property you\'re interested in on our website.'
            // },
            {
                question: 'How can I leave a review of the rental property after my stay?',
                answer: 'After your stay, we will send you a link to leave a review of the rental property on our website. Your feedback is greatly appreciated and helps us improve our services.'
            },
            {
                question: 'Do you offer any discounts or promotions for repeat customers?',
                answer: 'We do offer discounts and promotions for repeat customers from time to time. Please check our website or contact our customer support team for more information.'
            },
            {
                question: 'How do I contact your customer support team if I have any questions or issues during my stay?',
                answer: 'If you have any questions or issues during your stay, please do not hesitate to contact our customer support team. You can reach us by phone, email, or through our website\'s contact form. We\'re always here to help!'
            },
        ];

        let ac = 'accordian_';

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__faq-area mb-100">
            <div className="container">
            <div className="row">
                <div className="col-lg-8">
                <div className="ltn__faq-inner ltn__faq-inner-2">
                    
                    {/* card */}
                    {faqData.map((faq, index) => (
                        <div id={String(ac+index)} key={index}>
                        <div className="card">
                            <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">{faq.question}</h6>
                            <div id="faq-item-2-1" className="collapse" data-bs-parent={String('#'+ac+index)}>{/*"#accordion_2" "accordion_2"*/}
                            <div className="card-body">
                                <p>{faq.answer}</p>
                            </div>
                            </div>
                        </div>
                        </div>
                    ))}

                    {/* <div className="need-support text-center mt-100">
                    <h2>Still need help? Reach out to support 24/7:</h2>
                    <div className="btn-wrapper mb-30 go-top">
                        <Link to="/contact" className="theme-btn-1 btn">Contact Us</Link>
                    </div>
                    <h3><i className="fas fa-phone" /> +0123-456-789</h3>
                    </div> */}
                </div>
                </div>
                <div className="col-lg-4">
                <aside className="sidebar-area ltn__right-sidebar">
                    {/* Newsletter Widget */}
                    {/* <div className="widget ltn__search-widget ltn__newsletter-widget">                            
                    <h6 className="ltn__widget-sub-title">// subscribe</h6>
                    <h4 className="ltn__widget-title">Get Newsletter</h4>
                    <form action="#">
                        <input type="text" name="search" placeholder="Search" />
                        <button type="submit"><i className="fas fa-search" /></button>
                    </form>
                    <div className="ltn__newsletter-bg-icon">
                        <i className="fas fa-envelope-open-text" />
                    </div>
                    </div> */}
                    {/* Banner Widget */}
                    <div className="widget ltn__banner-widget go-top">
                    <Link to="shop.html"><img src={publicUrl+"assets/img/banner/banner-3.jpg"} alt="Banner Image" /></Link>
                    </div>
                </aside>
                </div>
            </div>
            </div>
        </div>
  
        }
}

export default FaqV1