import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Testimonial extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

		const clients = [
			{
				img: publicUrl+'assets/img/testimonial/1.jpg',
				review: 'I absolutely loved my stay! The host was incredibly welcoming and accommodating, and the apartment was beautifully decorated and impeccably clean. The location was also fantastic - I was able to walk to all of the major tourist attractions in the city. I would definitely recommend to anyone visiting the area!',
				name: 'Anthony Phillips'
			},
			{
				img: publicUrl+'assets/img/testimonial/2.jpg',
				review: 'I had a great experience. The host was extremely friendly and helpful, and the apartment was exactly as described - spacious, comfortable, and well-equipped. The location was also perfect for me, as it was close to public transportation and lots of great restaurants and shops. I would definitely stay here again!',
				name: 'Jacob Lutz'
			},
			{
				img: publicUrl+'assets/img/testimonial/3.jpeg',
				review: 'This was my first time staying, and I couldn\'t have been happier with my experience. The host was incredibly kind and welcoming, and the apartment was beautiful and spotlessly clean. The location was also fantastic - it was within walking distance of everything I wanted to see and do in the city. I would highly recommend to anyone!',
				name: 'Joseph Williams'
			},
			{
				img: publicUrl+'assets/img/testimonial/4.jpeg',
				review: 'I had the most incredible stay! The host went above and beyond to make me feel welcome and comfortable, even providing me with a map of the city highlighting the best local spots. The apartment itself was stunning. It had a gorgeous view of the city skyline and was decorated beautifully. I recommend to anyone!',
				name: 'Maria La Rosa'
			},
		];

    return <div className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70" data-bs-bg={publicUrl+"assets/img/bg/20.jpg"}>
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Testimonial</h6>
			          <h1 className="section-title">Clients Feedback</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
				{clients.map((client, index) => (
			      <div className="col-lg-4" key={index}>
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> {client.review}</p>
			            <div className="ltn__testimoni-info-inner">
			              {/* <div className="ltn__testimoni-img">
			                <img src={client.img} alt="#" />
			              </div> */}
			              <div className="ltn__testimoni-name-designation">
			                <h5>{client.name}</h5>
			                {/* <label>Selling Agents</label> */}
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				  ))}
			      {/*<div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
			              Precious ipsum dolor sit amet
			              consectetur adipisicing elit, sed dos
			              mod tempor incididunt ut labore et
			              dolore magna aliqua. Ut enim ad min
			              veniam, quis nostrud Precious ips
			              um dolor sit amet, consecte</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                <img src={publicUrl+"assets/img/testimonial/2.jpg"} alt="#" />
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Kelian Anderson</h5>
			                <label>Selling Agents</label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
			              Precious ipsum dolor sit amet
			              consectetur adipisicing elit, sed dos
			              mod tempor incididunt ut labore et
			              dolore magna aliqua. Ut enim ad min
			              veniam, quis nostrud Precious ips
			              um dolor sit amet, consecte</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                <img src={publicUrl+"assets/img/testimonial/3.jpg"} alt="#" />
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Adam Joseph</h5>
			                <label>Selling Agents</label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
				   <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
			              Precious ipsum dolor sit amet
			              consectetur adipisicing elit, sed dos
			              mod tempor incididunt ut labore et
			              dolore magna aliqua. Ut enim ad min
			              veniam, quis nostrud Precious ips
			              um dolor sit amet, consecte</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                <img src={publicUrl+"assets/img/testimonial/3.jpg"} alt="#" />
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>Adam Joseph</h5>
			                <label>Selling Agents</label>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__testimonial-item ltn__testimonial-item-7">
			          <div className="ltn__testimoni-info">
			            <p><i className="flaticon-left-quote-1" /> 
			              Precious ipsum dolor sit amet
			              consectetur adipisicing elit, sed dos
			              mod tempor incididunt ut labore et
			              dolore magna aliqua. Ut enim ad min
			              veniam, quis nostrud Precious ips
			              um dolor sit amet, consecte</p>
			            <div className="ltn__testimoni-info-inner">
			              <div className="ltn__testimoni-img">
			                <img src={publicUrl+"assets/img/testimonial/4.jpg"} alt="#" />
			              </div>
			              <div className="ltn__testimoni-name-designation">
			                <h5>James Carter</h5>
			                <label>Selling Agents</label>
			              </div>
			            </div>
			          </div>
			        </div>
				   </div> */}
			      {/*  */}
			    </div>
			  </div>
			</div>

        }
}

export default Testimonial