import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class TestimonialV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

		const clients = [
			{
				img: publicUrl+'assets/img/testimonial/1.jpg',
				review: 'I absolutely loved my stay! The host was incredibly welcoming and accommodating, and the apartment was beautifully decorated and impeccably clean. The location was also fantastic - I was able to walk to all of the major tourist attractions in the city. I would definitely recommend to anyone visiting the area!',
				name: 'Anthony Phillips'
			},
			{
				img: publicUrl+'assets/img/testimonial/2.jpg',
				review: 'I had a great experience. The host was extremely friendly and helpful, and the apartment was exactly as described - spacious, comfortable, and well-equipped. The location was also perfect for me, as it was close to public transportation and lots of great restaurants and shops. I would definitely stay here again!',
				name: 'Jacob Lutz'
			},
			{
				img: publicUrl+'assets/img/testimonial/3.jpeg',
				review: 'This was my first time staying, and I couldn\'t have been happier with my experience. The host was incredibly kind and welcoming, and the apartment was beautiful and spotlessly clean. The location was also fantastic - it was within walking distance of everything I wanted to see and do in the city. I would highly recommend to anyone!',
				name: 'Joseph Williams'
			},
			{
				img: publicUrl+'assets/img/testimonial/4.jpeg',
				review: 'I had the most incredible stay! The host went above and beyond to make me feel welcome and comfortable, even providing me with a map of the city highlighting the best local spots. The apartment itself was stunning. It had a gorgeous view of the city skyline and was decorated beautifully. I recommend to anyone!',
				name: 'Maria La Rosa'
			},
		];

    return <div className="ltn__testimonial-area ltn__testimonial-4 pt-115 pb-100 plr--9 go-top">
			  <div className="container-fluid">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Testimonial</h6>
			          <h1 className="section-title">Clients Feedback</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="ltn__testimonial-slider-4 ltn__testimonial-slider-4-active slick-arrow-1">
					{clients.map((client, index) => (
			          <div className="ltn__testimonial-item-5" key={index}>
			            <div className="ltn__quote-icon">
			              <i className="far fa-comments" />
			            </div>
			            {/* <div className="ltn__testimonial-image">
			              <img src={client.img} alt="quote" />
			            </div> */}
			            <div className="ltn__testimonial-info">
			              <p>{client.review}</p>
			              <h4>{client.name} </h4>
			              {/* <h6>Manager</h6> */}
			            </div>
			          </div>
					))}
			          {/*<div className="ltn__testimonial-item-5">
			            <div className="ltn__quote-icon">
			              <i className="far fa-comments" />
			            </div>
			            <div className="ltn__testimonial-image"><img src={publicUrl+"assets/img/testimonial/2.jpg"} alt="quote" /></div>
			            <div className="ltn__testimonial-info">
			              <p>Quidem Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud  ullamco laboris nisi ut aliquip ex ea. </p>
			              <h4>Ethan James</h4>
			              <h6>Admin</h6>
			            </div>
			          </div>
			          <div className="ltn__testimonial-item-5">
			            <div className="ltn__quote-icon">
			              <i className="far fa-comments" />
			            </div>
			            <div className="ltn__testimonial-image"><img src={publicUrl+"assets/img/testimonial/3.jpg"} alt="quote" /></div>
			            <div className="ltn__testimonial-info">
			              <p>Dolor Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud  ullamco nisi ut aliquip ex ea commodo. </p>
			              <h4>Noah Alexander</h4>
			              <h6>Professor</h6>
			            </div>
			          </div>
			          <div className="ltn__testimonial-item-5">
			            <div className="ltn__quote-icon">
			              <i className="far fa-comments" />
			            </div>
			            <div className="ltn__testimonial-image"><img src={publicUrl+"assets/img/testimonial/4.jpg"} alt="quote" /></div>
			            <div className="ltn__testimonial-info">
			              <p>Amet Ipsum Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud  nisi ut aliquip ex ea commodo.</p>
			              <h4>Liam Mason</h4>
			              <h6>Officer</h6>
			            </div>
					   </div>*/}
			        </div>
			        {/* <ul className="ltn__testimonial-quote-menu d-none d-lg-block">
			          <li><img src={publicUrl+"assets/img/testimonial/1.jpg"} alt="Quote image" /></li>
			          <li><img src={publicUrl+"assets/img/testimonial/2.jpg"} alt="Quote image" /></li>
			          <li><img src={publicUrl+"assets/img/testimonial/3.jpg"} alt="Quote image" /></li>
			          <li><img src={publicUrl+"assets/img/testimonial/4.jpg"} alt="Quote image" /></li>
			        </ul> */}
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default TestimonialV3