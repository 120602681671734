import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import emailjs from '@emailjs/browser';

// class ContactForm extends Component {

// 	componentDidMount() {

//     	const $ = window.$;
    	
//        	// Get the form.
// 	var form = $('#contact-form');

// 	// Get the messages div.
// 	var formMessages = $('.form-messege');

// 	// Set up an event listener for the contact form.
// 	$(form).submit(function(e) {
// 		// Stop the browser from submitting the form.
// 		e.preventDefault();
// 		// Serialize the form data.
// 		var formData = $(form).serialize();
// 		console.log("FORM", formData);

// 		// Submit the form using AJAX.
// 		$.ajax({
// 			type: 'POST',
// 			url: $(form).attr('action'),
// 			data: formData
// 		})
// 		.done(function(response) {
// 			// Make sure that the formMessages div has the 'success' class.
// 			$(formMessages).removeClass('error');
// 			$(formMessages).addClass('success');

// 			// Set the message text.
// 			$(formMessages).text(response);

// 			// Clear the form.
// 			$('#contact-form input,#contact-form textarea').val('');
// 		})
// 		.fail(function(data) {
// 			// Make sure that the formMessages div has the 'error' class.
// 			$(formMessages).removeClass('success');
// 			$(formMessages).addClass('error');

// 			// Set the message text.
// 			if (data.responseText !== '') {
// 				$(formMessages).text(data.responseText);
// 			} else {
// 				$(formMessages).text('Oops! An error occured and your message could not be sent.');
// 			}
// 		});
// 	});
//     }

//     render() {

const ContactForm = () => {

	const [ result, showresult ] = useState(false);
 
	const sendEmail = (e) => {
        e.preventDefault();
		emailjs
        .sendForm(
            'service_o317pqm', 
            'template_o9dh29c', 
            e.target, 
            'YWtW9YxRMGr2rPw-1'
        )
        .then((result) => {
            console.log(result.text);
            }, 
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
        showresult(true);
	}

	let publicUrl = process.env.PUBLIC_URL+'/'

    return (
			<div className="ltn__contact-message-area mb-120 mb--100">
				<div className="container">
				<div className="row">
					<div className="col-lg-12">
					<div className="ltn__form-box contact-form-box box-shadow white-bg">
						<h4 className="title-2">Alternatively Fill Out The Form Below:</h4>
					<form id="contact-form" onSubmit={sendEmail}>{/*action={publicUrl+"mail.php"} method="post">*/}
						<div className="row">
							<div className="col-md-6">
							<div className="input-item input-item-name ltn__custom-icon">
								<input type="text" name="name" placeholder="Enter your name" required/>
							</div>
							</div>
							<div className="col-md-6">
							<div className="input-item input-item-email ltn__custom-icon">
								<input type="email" name="email" placeholder="Enter email address" required/>
							</div>
							</div>
							{/* <div className="col-md-6">
							<div className="input-item" >
								<select className="nice-select" name="service">
								<option>Select Service Type</option>
								<option>Property Management </option>
								<option>Mortgage Service </option>
								<option>Consulting Service</option>
								<option>Home Buying</option>
								<option>Home Selling</option>
								<option>Escrow Services</option>
								</select>
							</div>
							</div> */}
							<div className="col-md-6">
							<div className="input-item input-item-phone ltn__custom-icon">
								<input type="text" name="phone" placeholder="Enter phone number" required/>
							</div>
							</div>
						</div>
						<div className="input-item input-item-textarea ltn__custom-icon">
							<textarea name="message" placeholder="Enter message" defaultValue={""} required/>
						</div>
						{/* <p><label className="input-info-save mb-0"><input type="checkbox" name="agree" /> Save my name, email, and website in this browser for the next time I comment.</label></p> */}
						<div className="btn-wrapper mt-0">
							<button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">Submit</button>
						</div>
						<br />
						{result && <h4 className="title-2">Your Message has been successfully sent. I will contact you soon.</h4>}
						<p className="form-messege mb-0 mt-20" />
					</form>
					</div>
					</div>
				</div>
				</div>
			</div>
        )
}

export default ContactForm