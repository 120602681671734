import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ContactInfo extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__contact-address-area mb-90">
				<div className="container">
				<div className="row">
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
					<a target="blank" href="mailto:alvie@skylinemanorholdings.com">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/icons/10.png"} alt="Icon Image" />
						</div>
						<h3 className='contact-child'>Email Address</h3>
						<p className='contact-child'>alvie@skylinemanorholdings.com<br /><br /><br /></p>
					</a>
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
					<a target="blank" href="tel:+17014840616">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/icons/11.png"} alt="Icon Image" />
						</div>
						<h3 className='contact-child'>Phone Number</h3>
						<p className='contact-child'>(701) 484-0616<br /><br /><br /></p>
					</a>
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
					<a target="blank" href="https://www.google.com/maps/search/2401+Fountain+View+Dr.+Ste+312+PMB2012+Houston,+TX+77057/@29.7443359,-95.487895,17z/data=!3m1!4b1">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/icons/12.png"} alt="Icon Image" />
						</div>
						<h3 className='contact-child'>Office Address</h3>
						<p className='contact-child'>2401 Fountain View Dr. Ste 464 PMB2012 Houston<br />
						Texas, United States</p>
					</a>
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default ContactInfo